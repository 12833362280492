<template>
  <v-row class="d-flex justify-center mt-3">
    <v-pagination
      :total-visible="5"
      :disabled="disabled"
      :length="paginate.last_page"
      v-model="paginate.current_page"
      class="mt-2 no-text-select"
      @input="setPage($event)"
    ></v-pagination>
  </v-row>
</template>

<script>
export default {
  name: "Paginate",

  props: {
    paginate: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    setPage(currentPage) {
      this.$emit("setPage", currentPage);
    },
  },
};
</script>